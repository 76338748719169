// src/components/HowTo.js

import React from 'react';
import { Box, Heading, Text, Stack } from '@chakra-ui/react';

const HowTo = () => {
  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Heading mb={6}>How to Use IndexerGlue</Heading>

      <Stack spacing={6}>
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Step 1: Sign Up and Log In
          </Heading>
          <Text>
            Visit our website and click on the "Sign Up" button. Fill in your details and verify your email. Once registered, log in to access your dashboard.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            Step 2: Generate Your API Key
          </Heading>
          <Text>
            Navigate to the "Dashboard" section. Click on the "Generate New API Key" button to create your API key. Keep this key secure as it allows access to the API.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            Step 3: Use the API
          </Heading>
          <Text> 
            Use this API Key as 'apikey' in your generic newznab configuration for all your configured indexers. When adding a new indexer, use the URL 'https://indexers.indexerglue.com/YOUR_INDEXER_NAME/api' and the API Key as the 'apikey' parameter.
          </Text>
        </Box>
        
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Step 4: Manage Your Indexers
          </Heading>
          <Text>
            Add, remove, or update your indexers from the dashboard. You can also view your indexer's API keys and configuration URLs.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default HowTo;

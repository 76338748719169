// src/components/APIDocumentation.js

import React from 'react';
import { Box, Heading, Text, Code } from '@chakra-ui/react';

const APIDocumentation = () => {
  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Heading mb={4}>API Documentation</Heading>

      <Heading as="h2" size="lg" mt={6} mb={2}>
        Introduction
      </Heading>
      <Text>
        Welcome to the API documentation for IndexerGlue. Our API allows you to manage multiple usenet indexers in one place with a single API key.
      </Text>

      <Heading as="h2" size="lg" mt={6} mb={2}>
        Authentication
      </Heading>
      <Text>
        All API requests require an API key. You can generate your API key from your dashboard.
      </Text>
      <Code p={2} my={2}>
        {`Authorization: Bearer YOUR_API_KEY`}
      </Code>

      <Heading as="h2" size="lg" mt={6} mb={2}>
        User API Key
      </Heading>
      <Heading as="h3" size="md" mt={4} mb={1}>
        POST /generate-user-api-key
      </Heading>
      <Text>
        Generate a new user API key.
      </Text>
      <Code p={2} my={2}>
        {`curl -H "Authorization: Bearer YOUR_API_KEY" https://api.indexerglue.com/api/get_api_key`}
      </Code>
      <Heading as="h4" size="sm" mt={4} mb={1}>
        Response
      </Heading>
      <Code p={2} my={2}>
        {`{
  "data": [...]
}`}
      </Code>

      <Heading as="h2" size="lg" mt={6} mb={2}>
        Indexer API Keys
      </Heading>
      <Heading as="h3" size="md" mt={4} mb={1}>
        POST /indexer-api-key
      </Heading>
      <Text>
        Create a new indexer API key.
      </Text>
      <Code p={2} my={2}>
        {`curl -H "Authorization: Bearer YOUR_API_KEY" https://api.indexerglue.com/api/indexer_config_url`}
      </Code>
      <Heading as="h4" size="sm" mt={4} mb={1}>
        Response
      </Heading>
      <Code p={2} my={2}>
        {`{
  "data": [...]
}`}
      </Code>
    </Box>
  );
};

export default APIDocumentation;

// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from '@chakra-ui/react';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import APIDocumentation from './components/APIDocumentation';
import HowTo from './components/HowTo';
import NavBar from './components/NavBar';
import Home from './components/Home';
import AccountInfo from './components/AccountInfo';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Stats from './components/Stats';

function App() {
  return (
    <Container maxW="container.lg">
      <Header />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
        <Route path="/apidocumentation" element={<ProtectedRoute component={APIDocumentation} />} />
        <Route path="/HowTo" element={<ProtectedRoute component={HowTo} />} />
        <Route path="/accountinfo" element={<ProtectedRoute component={AccountInfo} />} />
        <Route path="/stats" element={<ProtectedRoute component={Stats} />} />
      </Routes>
    </Container>
  );
}

export default App;

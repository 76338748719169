// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Heading,
  List,
  ListItem,
  Button,
  Input,
  Flex,
  Box,
  Stack,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard = () => {
  // State variables
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [userApiKey, setUserApiKey] = useState('');
  const [indexerName, setIndexerName] = useState('');
  const [newIndexerUrl, setNewIndexerUrl] = useState('');
  const [newIndexerApiParam, setNewIndexerApiParam] = useState('');
  const [newIndexerName, setNewIndexerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [indexerList, setIndexerList] = useState({
    items: [
      { label: "DogNZB", value: "dognzb" },
      { label: "Drunkenslug", value: "drunkenslug" },
      { label: "ABNzb", value: "abnzb" },
      { label: "Althub", value: "althub" },
      { label: "NZB Finder", value: "nzbfinder" },
      { label: "NZBGeek", value: "nzbgeek" },
      { label: "NZBPlanet", value: "nzbplanet" },
      { label: "NZB.su", value: "nzbsu" },
      { label: "UseNet-Crawler", value: "usenetcrawler" },
      { label: "Digitalcarnage", value: "digitalcarnage" },
      { label: "Scenenzb", value: "scenenzb" },
    ],
    items_backup: [
      { label: "DogNZB", value: "dognzb" },
      { label: "Drunkenslug", value: "drunkenslug" },
      { label: "ABNzb", value: "abnzb" },
      { label: "Althub", value: "althub" },
      { label: "NZB Finder", value: "nzbfinder" },
      { label: "NZBGeek", value: "nzbgeek" },
      { label: "NZBPlanet", value: "nzbplanet" },
      { label: "NZB.su", value: "nzbsu" },
      { label: "UseNet-Crawler", value: "usenetcrawler" },
      { label: "Digitalcarnage", value: "digitalcarnage" },
      { label: "Scenenzb", value: "scenenzb" },
    ],
  });

  // Auth0 hook to get access token
  const { getAccessTokenSilently } = useAuth0();
  const audience = "https://api.indexerglue.com/api/v1/";

  // Fetch API keys from the backend
  const fetchApiKeys = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read write:users read:users",
      });

      const indexerListResponse = await axios.get('https://api.indexerglue.com/api/list_user_indexers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // indexerListResponse.data is already JSON
      setApiKeys(indexerListResponse.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
      setError('Failed to load API keys.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch user API key from the backend
  const fetchUserApiKey = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read read:users",
      });

      const apiKeyResponse = await axios.get('https://api.indexerglue.com/api/get_api_key', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Now just use the returned api_key directly
      setUserApiKey(apiKeyResponse.data.api_key);
    } catch (error) {
      console.error('Error fetching user API key:', error);
      setError('Failed to load user API key.');
    } finally {
      setLoading(false);
    }
  };


  // Fetch initial data
  useEffect(() => {
    fetchApiKeys();
    fetchUserApiKey();
    makeIndexerList();
  }, []);

  // Add an API key
  const addApiKey = async () => {
    if (!newApiKey.trim()) {
      setError('API key cannot be empty.');
      return;
    }
    if (!indexerName.trim()) {
      setError('Indexer name cannot be empty.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read write:users write:indexers",
      });

      await axios.post(
        'https://api.indexerglue.com/api/update_user_indexers',
        {
          api_key: newApiKey,
          indexer_name: indexerName,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      await fetchApiKeys();
    } catch (error) {
      console.error('Error adding API key:', error);
      setError('Failed to add API key.');
    } finally {
      setLoading(false);
    }
  };

  // Add a new indexer
  const addNewIndexer = async () => {
    if (!newIndexerName.trim()) {
      setError('Indexer name cannot be empty.');
      return;
    }
    if (!newIndexerUrl.trim()) {
      setError('Indexer URL cannot be empty.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read write:users write:indexers",
      });

      await axios.post(
        'https://api.indexerglue.com/api/add_indexer',
        {
          name: newIndexerName,
          url: newIndexerUrl,
          api_param: newIndexerApiParam || 'apikey',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error(`Error adding new indexer: ${error}`);
      setError('Failed to add new indexer.');
    } finally {
      setLoading(false);
    }
  };

  // Delete an API key
  const deleteApiKey = async (indexerName) => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read write:users",
      });

      await axios.post(
        'https://api.indexerglue.com/api/delete_indexer_api_key',
        {
          user_api_key: userApiKey,
          api_key: "",
          indexer_name: indexerName, 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      await fetchApiKeys();
    } catch (error) {
      console.error('Error deleting API key:', error);
      setError('Failed to delete API key.');
    } finally {
      setLoading(false);
    }
  };

  // Make indexer list
  const makeIndexerList = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read read:indexers",
      });

      const indexerOptionList = await axios.get('https://api.indexerglue.com/api/get_indexers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = indexerOptionList.data;

      setIndexerList({
        items: Array.isArray(data) ? data : indexerList.items_backup,
      });
    } catch (error) {
      console.error('Error fetching indexer list:', error);
      setError('Failed to load indexer list.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <><Box mt="auto" >
      <Heading mb={2}>Indexer stats</Heading>  
    </Box>
    <Box mt="auto" >
        <Heading mb={3}>Your User API Key</Heading>
        {loading ? (
          <Spinner size="xl" />
        ) : (
          <>
            {userApiKey ? (
              <Text>{userApiKey}</Text>
            ) : (
              <Text>No user API keys found. Generate a new key in user settings</Text>
            )}
          </>
        )}

        <Heading mb={3} mt="auto" >Your Indexer API Keys</Heading>

        {error && (
          <Alert status="error" mb={2}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        {loading ? (
          <Spinner size="xl" />
        ) : (
          <>
            {Array.isArray(apiKeys.indexers) && apiKeys.indexers.length > 0 ? (
              <List spacing="auto" mb={1}>
                {apiKeys.indexers.map((key) => (
                  <ListItem key={key.indexer_name}>
                    <Flex align="center" justify="space-between">
                      <Box>{key.indexer_name} | {key.user_api_key}</Box>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => deleteApiKey(key.indexer_name)}
                      >
                        Delete
                      </Button>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Text>No Indexer API keys found</Text>
            )}

            <Heading mb={3} mt="auto" >Add a new indexer API key</Heading>
            <Stack mt={2} direction="row">
              <Input
                placeholder="Enter your indexer API key"
                value={newApiKey}
                onChange={(e) => setNewApiKey(e.target.value)} />
              <Select
                placeholder="Select Indexer"
                value={indexerName}
                onChange={(e) => setIndexerName(e.target.value)}
              >
                {Array.isArray(indexerList.items) &&
                  indexerList.items.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
              <Button colorScheme="teal" onClick={addApiKey}>
                Add API Key
              </Button>
            </Stack>

            <Heading mb={3} mt="auto" >Add a new indexer</Heading>
            <Stack mt={2} direction="row">
              <Input
                placeholder="Indexer Name"
                value={newIndexerName}
                onChange={(e) => setNewIndexerName(e.target.value)} />
                <InputGroup 
                    value={newIndexerUrl}
                    onChange={(e) => setNewIndexerUrl(e.target.value)}>
                  <InputLeftAddon>https://</InputLeftAddon>
                    <Input placeholder='my-indexer' />
                </InputGroup>
              <Input
                placeholder="Indexer API Parameter (optional)"
                value={newIndexerApiParam}
                onChange={(e) => setNewIndexerApiParam(e.target.value)} />
              <Button colorScheme="teal" onClick={addNewIndexer}>
                Add New Indexer
              </Button>
            </Stack>
          </>
        )}
      </Box></>
  );
};

export default Dashboard;

// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Heading,
  Button,
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const AccountInfo = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userApiKey, setUserApiKey] = useState(''); // New state variable
  
    // State variables
    // Auth0 hook to get access token
    const { getAccessTokenSilently } = useAuth0();
    const { user } = useAuth0();
    const audience = "https://api.indexerglue.com/api/v1/";
    // Function to fetch API keys from the backend

  // Function to fetch user API key from the backend
  const fetchUserApiKey = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read read:users",
      });

      const apiKeyResponse = await axios.get('https://api.indexerglue.com/api/get_api_key', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Now just use the returned api_key directly
      setUserApiKey(apiKeyResponse.data.api_key);
    } catch (error) {
      console.error('Error fetching user API key:', error);
      setError('Failed to load user API key.');
    } finally {
      setLoading(false);
    }
  };

  const addUser = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "profile email user:read user:update indexers:read write:users",
      });

      const response = await axios.post(
        'https://api.indexerglue.com/api/create_user',
        {}, // Empty data payload
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error creating user', error);
      setError('Error creating user');
    } finally {
      setLoading(false);
    }
  };

    useEffect(() => {
        fetchUserApiKey();
    }, []);

  return (
    <Box mt={8}>
      <Heading mb={4}>Your Auth Info</Heading>
        <Text>{user.email}</Text>
        <Heading mb={4}>Your User API Key</Heading>
        {loading ? (
  <Spinner size="xl" />
) : (
  <>
    {userApiKey ? (
      <Text>{userApiKey}</Text>
    ) : (
      <Text>Your user doesn't exist yet. Create your user account below</Text>
    )}
  </>
)}
<Box mt={6}>
  <Button colorScheme="teal" onClick={addUser}>
     Create and add user
  </Button>
</Box>

{error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

    </Box>

    );
};

export default AccountInfo;
